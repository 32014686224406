import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Hidden,
  styled,
} from "@mui/material";
import { FC } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { LogoAgency } from "../atoms/logo-agency";
import { HeaderSearchbar } from "./header-searchbar";
import { HeaderNotifications } from "./header-notifications";
import { SIDEBAR_WIDTH } from "./sidebar-menu";
import { useSubscriptionProvider } from "../../providers/subscription.provider";
import { LogoPartner } from "../atoms/logo-partner";

export const HEADER_HEIGHT = "64px";

export type HeaderProps = {
  isMenuExpanded: boolean;
  isNotificationsExpanded: boolean;
  onToggleMenu: () => void;
  onToggleNotifications: () => void;
};

export const Header: FC<HeaderProps> = ({
  isMenuExpanded,
  isNotificationsExpanded,
  onToggleMenu,
  onToggleNotifications,
}) => {
  const { subscription } = useSubscriptionProvider();
  const isPartner = !!subscription?.subscription?.plan.partner_id;

  return (
    <RootAppBar elevation={0}>
      <HeaderToolbar>
        <Hidden lgUp>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={onToggleMenu}
          >
            {isMenuExpanded ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
        </Hidden>
        <Box flex={{ xs: 1, md: "auto" }}>
          {isPartner ? <LogoPartner /> : <LogoAgency />}
        </Box>
        <ToolbarContainer>
          <HeaderSearchbar />
          <HeaderNotifications
            isNotificationsExpanded={isNotificationsExpanded}
            onToggle={onToggleNotifications}
          />
        </ToolbarContainer>
      </HeaderToolbar>
    </RootAppBar>
  );
};

const RootAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  zIndex: theme.zIndex.drawer + 2,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: SIDEBAR_WIDTH,
  },
}));

const HeaderToolbar = styled(Toolbar)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  height: HEADER_HEIGHT,
}));

const ToolbarContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(0.5),
  [theme.breakpoints.up("lg")]: {
    gap: theme.spacing(2),
  },
}));
