import { LinkProps, createTheme, keyframes } from "@mui/material";
import { LinkBehavior } from "./components/atoms/link-behavior";

const shadowSourceColor = "rgba(168,168,169,0.2)";
const shadowMiddleColor = "rgba(168,168,169,0.14)";
const shadowDestColor = "rgba(168,168,169,0.12)";

export const customScrollbar = {
  "::-webkit-scrollbar": {
    width: 4,
    height: 8,
    backgroundColor: "#cacaca",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "#A8A8A9",
    borderRadius: 4,
  },
};

export const borderGradientAnimation = keyframes`
  0% { border-image-slice: 1; border-image-source: linear-gradient(90deg, #336e99 0%, #6ff1c7 30.43%, #336e99 68.23%, #6ff1c7 100%); }
  50% { border-image-slice: 1; border-image-source: linear-gradient(90deg, #6ff1c7 0%, #336e99 30.43%, #6ff1c7 68.23%, #336e99 100%); }
  100% { border-image-slice: 1; border-image-source: linear-gradient(90deg, #336e99 0%, #6ff1c7 30.43%, #336e99 68.23%, #6ff1c7 100%); }
`;

const partnerTheme = createTheme({
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: { xs: 0, sm: 600, md: 900, lg: 1200, xl: 1536 },
  },
  typography: {
    fontFamily: "Roboto",
    allVariants: {
      color: "rgb(69,69,69)",
    },
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2.325rem",
    },
    h3: {
      fontSize: "1.85rem",
    },
    h4: {
      fontSize: "1.5rem",
    },
    h5: {
      fontSize: "1.125rem",
    },
  },
  shadows: [
    "none",
    `0px 2px 1px -1px ${shadowSourceColor},0px 1px 1px 0px ${shadowMiddleColor},0px 1px 3px 0px ${shadowDestColor}`,
    `0px 3px 1px -2px ${shadowSourceColor},0px 2px 2px 0px ${shadowMiddleColor},0px 1px 5px 0px ${shadowDestColor}`,
    `0px 3px 3px -2px ${shadowSourceColor},0px 3px 4px 0px ${shadowMiddleColor},0px 1px 8px 0px ${shadowDestColor}`,
    `0px 2px 4px -1px ${shadowSourceColor},0px 4px 5px 0px ${shadowMiddleColor},0px 1px 10px 0px ${shadowDestColor}`,
    `0px 3px 5px -1px ${shadowSourceColor},0px 5px 8px 0px ${shadowMiddleColor},0px 1px 14px 0px ${shadowDestColor}`,
    `0px 3px 5px -1px ${shadowSourceColor},0px 6px 10px 0px ${shadowMiddleColor},0px 1px 18px 0px ${shadowDestColor}`,
    `0px 4px 5px -2px ${shadowSourceColor},0px 7px 10px 1px ${shadowMiddleColor},0px 2px 16px 1px ${shadowDestColor}`,
    `0px 5px 5px -3px ${shadowSourceColor},0px 8px 10px 1px ${shadowMiddleColor},0px 3px 14px 2px ${shadowDestColor}`,
    `0px 5px 6px -3px ${shadowSourceColor},0px 9px 12px 1px ${shadowMiddleColor},0px 3px 16px 2px ${shadowDestColor}`,
    `0px 6px 6px -3px ${shadowSourceColor},0px 10px 14px 1px ${shadowMiddleColor},0px 4px 18px 3px ${shadowDestColor}`,
    `0px 6px 7px -4px ${shadowSourceColor},0px 11px 15px 1px ${shadowMiddleColor},0px 4px 20px 3px ${shadowDestColor}`,
    `0px 7px 8px -4px ${shadowSourceColor},0px 12px 17px 2px ${shadowMiddleColor},0px 5px 22px 4px ${shadowDestColor}`,
    `0px 7px 8px -4px ${shadowSourceColor},0px 13px 19px 2px ${shadowMiddleColor},0px 5px 24px 4px ${shadowDestColor}`,
    `0px 7px 9px -4px ${shadowSourceColor},0px 14px 21px 2px ${shadowMiddleColor},0px 5px 26px 4px ${shadowDestColor}`,
    `0px 8px 9px -5px ${shadowSourceColor},0px 15px 22px 2px ${shadowMiddleColor},0px 6px 28px 5px ${shadowDestColor}`,
    `0px 8px 10px -5px ${shadowSourceColor},0px 16px 24px 2px ${shadowMiddleColor},0px 6px 30px 5px ${shadowDestColor}`,
    `0px 8px 11px -5px ${shadowSourceColor},0px 17px 26px 2px ${shadowMiddleColor},0px 6px 32px 5px ${shadowDestColor}`,
    `0px 9px 11px -5px ${shadowSourceColor},0px 18px 28px 2px ${shadowMiddleColor},0px 7px 34px 6px ${shadowDestColor}`,
    `0px 9px 12px -6px ${shadowSourceColor},0px 19px 29px 2px ${shadowMiddleColor},0px 7px 36px 6px ${shadowDestColor}`,
    `0px 10px 13px -6px ${shadowSourceColor},0px 20px 31px 3px ${shadowMiddleColor},0px 8px 38px 7px ${shadowDestColor}`,
    `0px 10px 13px -6px ${shadowSourceColor},0px 21px 33px 3px ${shadowMiddleColor},0px 8px 40px 7px ${shadowDestColor}`,
    `0px 10px 14px -6px ${shadowSourceColor},0px 22px 35px 3px ${shadowMiddleColor},0px 8px 42px 7px ${shadowDestColor}`,
    `0px 11px 14px -7px ${shadowSourceColor},0px 23px 36px 3px ${shadowMiddleColor},0px 9px 44px 8px ${shadowDestColor}`,
    `0px 11px 15px -7px ${shadowSourceColor},0px 24px 38px 3px ${shadowMiddleColor},0px 9px 46px 8px ${shadowDestColor}`,
  ],
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: ({ theme }) => ({
          ...customScrollbar,
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        shrink: {
          transform: "translate(20px, -9px) scale(0.75)",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 0,
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderLeftWidth: "0.4rem",
          },
          "&.Mui-error.Mui-disabled .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.error.main,
          },
        }),
        notchedOutline: ({ theme }) => ({
          transition: theme.transitions.create(["border-left-width"], {
            duration: theme.transitions.duration.shortest,
          }),
          // borderLeftWidth: "0.4rem",
        }),
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "contained" },
          style: (theme) => ({ borderRadius: "2rem" }),
        },
        {
          props: { variant: "ia" },
          style: ({ theme }) => ({
            borderWidth: 2.5,
            borderStyle: "solid",
            borderImageSlice: 1,
            color: theme.palette.primary.main,
            fontWeight: 700,
            borderImageSource:
              "linear-gradient(90deg, #336e99 0%, #6ff1c7 30.43%, #336e99 68.23%, #6ff1c7 100%)",
            ":hover": {
              animation: `${borderGradientAnimation} 1s linear infinite`,
            },
          }),
        },
      ],
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          ...customScrollbar,
        },
      },
    },
  },
  palette: {
    background: {
      default: "#f4f7fe",
      paper: "#ffffff",
    },
    primary: {
      main: "#00a9ab",
      light: "#00a9ab",
      contrastText: "#fff",
    },
    secondary: {
      main: "#002d5d",
      contrastText: "#fff",
    },
    success: {
      main: "#2e7d32",
      light: "#6bc990",
      contrastText: "#fff",
    },
    warning: {
      main: "#ffc72a",
      light: "#ffc72a",
      contrastText: "#fff",
    },
    error: {
      main: "#ff5200",
      light: "#ff5200",
      contrastText: "#fff",
    },
    info: {
      main: "#135588",
      light: "#135588",
      contrastText: "#fff",
    },
  },
});

export default partnerTheme;
