import { Typography, styled } from "@mui/material";
import { FC } from "react";
import { useAgencyProvider } from "../../providers/agency.provider";
import { getContentUrl } from "../../utils";

export type LogoPartnerProps = {
  className?: string;
};

export const LogoPartner: FC<LogoPartnerProps> = ({ className }) => {
  const { agency } = useAgencyProvider();
  const logotype = agency?.assets?.find(
    (x) => x.asset_type_code === "logotype"
  );

  if (!agency) return null;

  return (
    <Anchor className={className} href={"/"}>
      {!logotype && (
        <Box>
          <img
            style={{ width: 35 }}
            alt={agency?.comercial_name}
            src="/images/cuadrapi-slide.png"
          />
          <Typography
            component="span"
            fontWeight="700"
            fontSize={22}
            noWrap
            textOverflow="ellipsis"
            marginTop="10px"
          >
            {agency?.comercial_name}
          </Typography>
        </Box>
      )}
      {logotype && (
        <Image
          alt={agency.comercial_name}
          src={getContentUrl(logotype?.document_id ?? "")}
        />
      )}
    </Anchor>
  );
};

const Box = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

const Anchor = styled("a")(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  textDecoration: "none",
  [theme.breakpoints.up("lg")]: {
    justifyContent: "flex-start",
  },
}));

const Image = styled("img")(({ theme }) => ({
  width: "auto",
  height: "auto",
  maxWidth: 200,
  maxHeight: 48,
  [theme.breakpoints.up("lg")]: {
    maxWidth: 200,
    maxHeight: 54,
  },
}));
