import { useCallback } from "react";
import { useAPI } from "../../hooks/use-api";
import { UserAvatar } from "./user-avatar";
import { useFetchController } from "@serviestate/react-ui";
import { getContentUrl } from "../../utils";
import { styled } from "@mui/material";
import { AgencyUser, UserStatus } from "@serviestate/shared";
import { useSubscriptionProvider } from "../../providers/subscription.provider";

export function AgentAvatar({
  userId,
  size,
}: {
  userId: string;
  size?: number;
}) {
  const api = useAPI();
  const { subscription } = useSubscriptionProvider();
  const isPartner = !!subscription?.subscription?.plan.partner_id;

  const fetchAgent = useCallback(
    () => api.agents.detail(userId),
    [api, userId]
  );

  const fetchStatus = useCallback(
    () => api.user.getStatus(userId),
    [api, userId]
  );

  const { data: agent } = useFetchController<AgencyUser, Error>(fetchAgent, {
    autoload: true,
  });

  const { data: status } = useFetchController<UserStatus, Error>(fetchStatus, {
    autoload: true,
  });

  return (
    <Container>
      <UserAvatar
        username={agent?.user.fullname ?? "loading"}
        className={isPartner ? "agent-avatar" : ""}
        avatar={
          agent?.user.avatar_id
            ? getContentUrl(agent.user.avatar_id, { width: 80, height: 80 })
            : null
        }
        size={size}
        status={status}
      />
    </Container>
  );
}

const Container = styled("div")(({ theme }) => ({
  ".agent-avatar": {
    backgroundColor: theme.palette.secondary.main,
    padding: 5,
  },
}));
