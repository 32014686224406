import { Box, Drawer, List, styled, Theme, useMediaQuery } from "@mui/material";
import { FC } from "react";
import { useActivePage } from "../../hooks/use-active-page";
import { useUserProvider } from "../../providers/user.provider";
import { SidebarMenuItem } from "./sidebar-menu-item";
import {
  TfiAgenda,
  TfiDashboard,
  TfiMapAlt,
  TfiSettings,
} from "react-icons/tfi";
import { RiContactsBookLine, RiHomeWifiLine } from "react-icons/ri";
import { HiOutlineChatAlt } from "react-icons/hi";
import {
  MdOutlineCampaign,
  MdOutlineLocalOffer,
  MdOutlinePoll,
} from "react-icons/md";
import { BiWorld } from "react-icons/bi";
import { IconType } from "react-icons";
import { canAccess } from "@serviestate/shared";
import { useRoleProvider } from "../../providers/role.provider";
import { FiUserPlus } from "react-icons/fi";
import { LogoServiestate } from "../atoms/logo-serviestate";
import { HeaderUser } from "./header-user";
import { useSubscriptionProvider } from "../../providers/subscription.provider";
import { LogoApi } from "../atoms/logo-api";

export const SIDEBAR_WIDTH = 240;

export type SidebarMenuProps = {
  open: boolean;
  onClose: () => void;
};

const MENUS: { icon: IconType; name: string; pageId: string; path: string }[] =
  [
    { icon: TfiDashboard, name: "Panel", pageId: "home", path: "/" },
    { icon: TfiAgenda, name: "Agenda", pageId: "agenda", path: "/agenda" },
    {
      icon: MdOutlineLocalOffer,
      name: "Demandas",
      pageId: "demands",
      path: "/demands",
    },
    {
      icon: RiContactsBookLine,
      name: "Contactos",
      pageId: "contacts",
      path: "/contacts",
    },
    {
      icon: RiHomeWifiLine,
      name: "Inmuebles",
      pageId: "properties",
      path: "/properties",
    },
    {
      icon: FiUserPlus,
      name: "Captación",
      pageId: "acquisition",
      path: "/acquisition",
    },
    {
      icon: TfiMapAlt,
      name: "Proyectos",
      pageId: "projects",
      path: "/projects",
    },
    {
      icon: HiOutlineChatAlt,
      name: "ServiBox",
      pageId: "messages",
      path: "/messages",
    },
    { icon: BiWorld, name: "Web", pageId: "web", path: "/web" },
    {
      icon: MdOutlineCampaign,
      name: "Newsletters",
      pageId: "newsletters",
      path: "/newsletters",
    },
    {
      icon: MdOutlinePoll,
      name: "Encuestas",
      pageId: "polls",
      path: "/polls",
    },
    {
      icon: TfiSettings,
      name: "Administración",
      pageId: "administration",
      path: "/administration",
    },
  ];

export const SidebarMenu: FC<SidebarMenuProps> = ({ open, onClose }) => {
  const [page] = useActivePage();
  const { user } = useUserProvider();
  const { role } = useRoleProvider();
  const { subscription } = useSubscriptionProvider();

  const planConfig = subscription?.subscription?.plan_version?.config ?? {};

  const isLargeScreen = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up("lg")
  );
  const isPartner = !!subscription?.subscription?.plan.partner_id;

  if (!user || !role) return null;

  const availableMenu = MENUS.filter((x) =>
    canAccess(
      x.pageId,
      planConfig,
      role.grant_permissions,
      role.deny_permissions
    )
  );

  return (
    <RootDrawer
      variant={isLargeScreen ? "permanent" : "temporary"}
      anchor="left"
      open={open || isLargeScreen}
      onClose={onClose}
    >
      <HeaderUser />

      <List>
        {availableMenu.map((x, i) => (
          <SidebarMenuItem
            key={i}
            icon={x.icon}
            name={x.name}
            active={page === x.pageId}
            path={x.path}
            onClick={onClose}
          />
        ))}
      </List>
      <Box flex={1} />
      <Box sx={{ px: 1.5, py: 1 }}>
        {isPartner ? <LogoApi size="small" /> : <LogoServiestate />}
      </Box>
    </RootDrawer>
  );
};

const RootDrawer = styled(Drawer)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  zIndex: theme.zIndex.drawer + 100,
  "& .MuiDrawer-paper": {
    width: SIDEBAR_WIDTH,
  },
}));
