import { useEffect, useState } from "react";
import { useWalletsProvider } from "../providers/wallets.provider";
import { AgencyWallet } from "@serviestate/shared";
import { useSubscriptionProvider } from "../providers/subscription.provider";

export function useWallet(walletType: string) {
  const { subscription } = useSubscriptionProvider();
  const { wallets, refresh } = useWalletsProvider();
  const [wallet, setWallet] = useState<AgencyWallet | null>(null);

  useEffect(() => {
    const wallet =
      wallets.find((x) => x.wallet_type_code === walletType) ?? null;
    setWallet(wallet);
  }, [wallets, walletType]);

  useEffect(() => {
    refresh();
  }, [subscription, refresh]);

  return { data: wallet, refresh };
}
