import { canAccess } from "@serviestate/shared";
import { useRoleProvider } from "../providers/role.provider";
import { useSubscriptionProvider } from "../providers/subscription.provider";

export function useCanAccess(actionPath: string) {
  const { role } = useRoleProvider();
  const { subscription } = useSubscriptionProvider();
  return (
    role &&
    canAccess(
      actionPath,
      subscription?.subscription?.plan_version?.config ?? {},
      role.grant_permissions,
      role.deny_permissions
    )
  );
}
